/*
https://github.com/prc5/react-zoom-pan-pinch/issues/112
*/
.react-transform-component {
  width: 100% !important;
  height: 100% !important;
}

.react-transform-element {
  width: 100% !important;
  height: 100% !important;
}

table {
  border-spacing: 0;
}
td {
  border-spacing: 0;
  padding: 0;
  margin: 0;
  /* background-color: #ff0000; */
}

.csvDropzoneArea {
  padding: 1rem;
  min-height: 180px !important;
}